import React from "react";

export function getFirestoreStatusIndicator(mostRecentSaveEventTime) {
  const currentTimeMilis = Date.now();
  // 600 is 10 minutes in seconds
  const tenMinutesAgo = new Date(currentTimeMilis - (600 * 1000));
  const oneHourAgo = new Date(currentTimeMilis - (3600 * 1000));
  if(mostRecentSaveEventTime >= tenMinutesAgo) {
    return (
      <div data-tip="FileEvent Received in last ten minutes.">
        <status-indicator active pulse/>
      </div>
    )
  } else if(mostRecentSaveEventTime >= oneHourAgo) {
    return (
      <div data-tip="No FileEvent Received in last ten minutes.">
        <status-indicator intermediary pulse/>
      </div>
    )
  } else {
    return (
      <div data-tip="No FileEvent Received in last hour.">
        <status-indicator negative pulse/>
      </div>
      )
    }
}


