const stageFirebaseConfig = {
  apiKey: "AIzaSyAp5sU0cEm3703_rQz1HwV-dYS7Z5XsXV8",
  authDomain: "stage-data-1.firebaseapp.com",
  databaseURL: "https://stage-data-1-default-rtdb.firebaseio.com",
  projectId: "stage-data-1",
  storageBucket: "stage-data-1.appspot.com",
  messagingSenderId: "1034576831550",
  appId: "1:1034576831550:web:65319b3037b8a747581d6f"
};

export function getFirebaseConfig() {
  return stageFirebaseConfig;
}
