import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import Autosuggest from 'react-autosuggest';
import TimeAgo from 'javascript-time-ago';
import ReactTimeAgo from 'react-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import LoadingIcons from 'react-loading-icons';
import { getFirestoreStatusIndicator } from "./firestore-status-indicator";
import 'status-indicator/styles.css';
import * as firebase from 'firebase/app';
import bigqueryIcon from "./bigquery.png";
import dataflowIcon from "./dataflow.png";
import robotArmIcon from "./robot-arm.jpeg";
import cloudGenericIcon from "./cloud_generic.png";
import errorIcon from "./error_icon.webp";

import {getAuth, GoogleAuthProvider,  getRedirectResult, signInWithRedirect} from 'firebase/auth';

import { getDocs, doc, getFirestore, collection, where, limit, orderBy, onSnapshot, query } from 'firebase/firestore';

import './App.css';
import { getFirebaseConfig } from "./firebase-config";

const firebaseConfig = getFirebaseConfig();

const NUM_REQUESTS_LOADED = 20;
const NUM_SUGGESTIONS_TO_DISPLAY = 5;
const STATUS_REFRESH_INTERVAL_SECONDS = 5;

// TODO: add encrypted transfer ones
const fileStartName = "FileUploadStart";
const fileSuccessName = "FileUploadSuccess";
const fileFailureName = "FileUploadFailure";
const fileResumeName = "FileUploadResume";
const fileUnknownName = "UnknownEvent";
const fileIngestedName = "FileIngested";
const fileProcessingStartedName = "FileProcessingStarted";

const hlcList = [
    "osr-dem-mod01-1",
    "osr-dem-pak01-1",
    "osr-dem-pak01-2",
    "osr-hq-gp7-1",
    "osr-hq-gp7-2",
    "osr-hq-hc10-1",
    "osr-hq-iiwa-1",
    "osr-hq-kr10-1",
    "osr-hq-lev-1",
    "osr-hq-lrm-1",
    "osr-hq-m10-1",
    "osr-hq-m10-2",
    "osr-hq-m20-1",
    "osr-hq-ov-1",
    "osr-hq-ov-4",
    "osr-hq-ov-5",
    "osr-hq-ur10-1",
    "osr-mob-lrm-1",
    "osr-tok-gp7-1",
    "osr-tok-ov-1",
    "osr-tok-ur5e-1",
];

const typeList = [
  "placeholder_type_1",
  "placeholder_type_2",
];



function App() {
  TimeAgo.addDefaultLocale(en);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [firestoreApp, setFirestoreApp] = useState(firebase.initializeApp(firebaseConfig));
  const [firestoreDb, setFirestoreDb] = useState(getFirestore(firestoreApp));
  const [firestoreAuth, setFirestoreAuth] = useState(getAuth(firestoreApp));
  const [requestIdInput, setRequestIdInput] = useState("");
  const [requestIdSearch, setRequestIdSearch] = useState("");
  const [hlcNameInput, setHlcNameInput] = useState("");
  const [hlcNameSearch, setHlcNameSearch] = useState("");
  const [saveEventTypeInput, setSaveEventTypeInput] = useState("");
  const [saveEventTypeSearch, setSaveEventTypeSearch] = useState("");
  const [saveEventIdToDocument, setSaveEventIdToDocument] = useState({});
  const [saveEventRequestsLoaded, setSaveEventRequestsLoaded] = useState(false);
  const [requestIdJsonDisplay, setRequestIdJsonDisplay] = useState("");
  const [displayData, setDisplayData] = useState(null);
  const [queryUnsubscribe, setQueryUnsubscribe] = useState(null);
  const [hlcNameToStatus, setHlcNameToStatus] = useState({});
  const [selectedHlcName, setSelectedHlcName] = useState("");
  const [hlcNameSuggestions, setHlcNameSuggestions] = useState([]);
  const [typeSuggestions, setTypeSuggestions] = useState([]);
  const [hlcUnsubscribeFunction, setHlcUnsubscribeFunction] = useState(null);
  const [hlcViewEnabled, setHlcViewEnabled] = useState(true);
  const [mostRecentSaveEventTime, setMostRecentSaveEventTime] = useState(new Date());

  const hlcAutosuggestProps = {
    placeholder: 'Enter HLC Name',
    value: hlcNameInput,
    onChange: hlcAutosuggestionOnChange,
    onKeyDown: onEnterQuery
  };

  // TODO: implement type search
  const typeAutosuggestProps = {
    placeholder: 'Not yet implemented.',
    value: saveEventTypeInput,
    onChange: typeAutosuggestionOnChange,
    onKeyDown: onEnterQuery
  };

  function hlcAutosuggestionOnChange(event, { newValue } ) {
    setHlcNameInput(newValue);
  }

  function typeAutosuggestionOnChange(event, { newValue } ) {
    setSaveEventTypeInput(newValue);
  }

  // Initialize Firebase
  const provider = new GoogleAuthProvider();
  provider.addScope('profile');
  provider.addScope('email');

  function onChangeRequestId(ev) {
    setRequestIdInput(ev.target.value);
  }

  function onSubmitRequestId(ev) {
    setRequestIdSearch(requestIdInput);
    setHlcViewEnabled(false);
    setDisplayData({});
  }

  function onEnterRequestId(ev) {
    if(ev.key === 'Enter') {
      setRequestIdSearch(requestIdInput);
      setHlcViewEnabled(false);
      setDisplayData({});
    }
  }

  function onSubmitQuery(ev) {
    setHlcNameSearch(hlcNameInput);
    setSaveEventTypeSearch(saveEventTypeInput);
    setHlcViewEnabled(false);
  }

  function onEnterQuery(ev) {
    if(ev.key === 'Enter') {
      setHlcNameSearch(hlcNameInput);
      setSaveEventTypeSearch(saveEventTypeInput);
      setHlcViewEnabled(false);
    }
  }

  function getStatusForSaveEvent(saveEventDoc) {
    // Build a dictionary of event type to file event list
    const fileEventDict = {};
    for(let i = 0; i < saveEventDoc.file_events.length; i++) {
      const file_event = saveEventDoc.file_events[i];
      // The file_events list can contain more than one event of the same type but we only care about the most recent
      // which is the last event.
      fileEventDict[file_event.event_type] = file_event;
    }

    if(fileIngestedName in fileEventDict) {
      // File has been ingested into BigQuery
      return fileIngestedName;
    }

    if(fileProcessingStartedName in fileEventDict) {
      // Dataflow is processing the file
      return fileProcessingStartedName;
    }

    if(fileSuccessName in fileEventDict) {
      return fileSuccessName;
    }

    if(fileFailureName in fileEventDict) {
      if(fileResumeName in fileEventDict) {
        const resume_time = fileEventDict[fileResumeName].occurred_on["seconds"];
        const failure_time = fileEventDict[fileFailureName].occurred_on["seconds"];
        if(resume_time > failure_time) {
          return fileResumeName;
        }
      }
      return fileFailureName;
    }

    if(fileStartName in fileEventDict) {
      return fileStartName;
    }

    return fileUnknownName;
  }

  // Load firestore status
  useEffect(() => {
    async function updateFirestoreStatus() {
      const lastUploadedQuery = query(
        collection(firestoreDb, 'garmr-save-events'),
        orderBy("upload_started_on", "desc"),
        limit(1)
      );

      const querySnapshot = await getDocs(lastUploadedQuery);
      querySnapshot.forEach((saveEventDoc) => {
        const lastRequestTime = new Date(saveEventDoc.data().upload_started_on);
        setMostRecentSaveEventTime(lastRequestTime);
      });
    }
    const interval = setInterval(() => {
      updateFirestoreStatus();
    }, STATUS_REFRESH_INTERVAL_SECONDS * 1000);

    return () => clearInterval(interval);
  }, [])

  // Get status of each HLC
  useEffect(() => {
    async function updateHlcStatuses() {
      let newHlcNameToStatus = {};
      for(const hlcName of hlcList) {
        const lastHLCRecord = query(
          collection(firestoreDb, 'garmr-save-events'),
          where("createdBy", "==", hlcName),
          orderBy("upload_started_on", "desc"),
          limit(1)
        );

        const querySnapshot = await getDocs(lastHLCRecord);
        const saveEventDocuments = querySnapshot.docs;
        if(saveEventDocuments.length > 0) {
          const lastRequestTime = new Date(saveEventDocuments[0].data().upload_started_on);
          const currentTimeMilis = Date.now();
          const oneHourAgo = new Date(currentTimeMilis - (3600 * 1000));

          if(lastRequestTime >= oneHourAgo) {
            newHlcNameToStatus[hlcName] = "active";
          } else {
            newHlcNameToStatus[hlcName] = "inactive";
          }
        } else {
          newHlcNameToStatus[hlcName] = "inactive";
        }
      }
      setHlcNameToStatus(newHlcNameToStatus);
    }

    // Initialize map to all inactive.
    let newHlcNameToStatus = {};
    for(const hlcName of hlcList) {
      newHlcNameToStatus[hlcName] = "loading";
    }
    setHlcNameToStatus(newHlcNameToStatus);

    // Run immediately to populate list on page load
    updateHlcStatuses();
    const interval = setInterval(() => {
      updateHlcStatuses();
    }, STATUS_REFRESH_INTERVAL_SECONDS * 1000);

    return () => clearInterval(interval);
  }, [])

  useEffect(() => {
    getRedirectResult(firestoreAuth).then((authResult) => {
      if(authResult) {
        if (authResult.credential) {
          // This gives you a Google Access Token.
          setToken(authResult.credential.accessToken);
        }
      }
      return authResult;
    }).then((authResult) => {
      if(firestoreAuth.currentUser) {
        setUser(firestoreAuth.currentUser);
      } else {
        signInWithRedirect(firestoreAuth, provider).then((result) => {

        });
      }
    });
  },[]);

  // Get data for HLC SaveEvent Request page
  useEffect(() => {
    if (hlcUnsubscribeFunction) {
      hlcUnsubscribeFunction();
    }

    if (hlcViewEnabled && selectedHlcName) {
      setSaveEventRequestsLoaded(false);
      const lastUploadedQuery = query(
        collection(firestoreDb, 'garmr-save-events'),
        where("createdBy", "==", selectedHlcName),
        orderBy("upload_started_on", "desc"),
        limit(NUM_REQUESTS_LOADED)
      );
      const unsub = onSnapshot(lastUploadedQuery, (querySnapshot) => {
        // Update status based on most recent request start.
        const saveEventDocuments = querySnapshot.docs;
        // Save Requests for all HLCs.
        const newSaveEventIdToDocument = buildSaveEventIdToDataMap(saveEventDocuments);
        setSaveEventIdToDocument(newSaveEventIdToDocument);
        setSaveEventRequestsLoaded(true);
      });
      setHlcUnsubscribeFunction(() => unsub);
    } else {
      setSaveEventRequestsLoaded(true);
      setHlcUnsubscribeFunction(null);
    }
  }, [user, hlcViewEnabled, selectedHlcName]);

  useEffect(() => {
    if(user && requestIdSearch) {
      // May have a listener attached from the previous query
      if (queryUnsubscribe) {
        queryUnsubscribe();
      }
      const unsub = onSnapshot(doc(firestoreDb, "garmr-save-events", requestIdSearch), (saveEventDoc) => {
        const newSaveEventIdToDocument = {};
        const saveEventData = saveEventDoc.data();
        saveEventData["status"] = getStatusForSaveEvent(saveEventData);
        newSaveEventIdToDocument[saveEventData.id] = saveEventData;
        setSaveEventIdToDocument(newSaveEventIdToDocument);
      });
      setQueryUnsubscribe(() => unsub);
    }
  },[requestIdSearch]);

  useEffect(() => {
      if(user) {
        // May have a listener attached from the previous query
        if (queryUnsubscribe) {
          queryUnsubscribe();
        }
        const queryConstraints = [orderBy("upload_started_on", "desc"), limit(20)];
        if(hlcNameSearch) {
          queryConstraints.push(where("createdBy", "==", hlcNameSearch));
        }

        if(saveEventTypeSearch) {
          queryConstraints.push(where("payload.$@type", "==", saveEventTypeSearch));
        }

        // Overwrite last result in case query returns nothing
        setSaveEventIdToDocument({});
        const q = query(collection(firestoreDb, 'garmr-save-events'), ...queryConstraints);
        const unsub = onSnapshot(q, (querySnapshot) => {
          const newSaveEventIdToDocument = buildSaveEventIdToDataMap(querySnapshot.docs)
          setSaveEventIdToDocument(newSaveEventIdToDocument);
        });
        setQueryUnsubscribe(() => unsub);
      }
  },[hlcNameSearch, saveEventTypeSearch]);

  function buildSaveEventIdToDataMap(saveEventDocuments) {
    const saveEventIdToDocument = {};
    saveEventDocuments.forEach((saveEventDoc) => {
      const saveEventData = saveEventDoc.data();
      saveEventData["status"] = getStatusForSaveEvent(saveEventData);
      saveEventIdToDocument[saveEventData.id] = saveEventData;
    });
    return saveEventIdToDocument;
  }

  // Reduces brightness if the input SaveEvent Id is the selected SaveEvent ID.
  function getClassForRequestFilter(id) {
    if(id === requestIdJsonDisplay) {
      return "clicked";
    } else {
      return "";
    }
  }

  function getHlcStatusIndicator(status) {
    if(status === "active") {
      return (
        <div className="hlcStatusIndicator" data-tip="Active in last hour.">
          <status-indicator active pulse/>
        </div>
      )
    } else if(status == "loading") {
      return (
        <div className="hlcStatusIndicator" data-tip="Loading status.">
          <status-indicator  intermediary pulse/>
        </div>
      )
    }
      return (
        <div className="hlcStatusIndicator" data-tip="No requests in last hour.">
          <status-indicator  pulse/>
        </div>
      )
  }

  // Reduces brightness if hlcName is the selected hlcName
  function getClassForHlcFilter(hlcName) {
    if(hlcName === selectedHlcName) {
      return "clicked";
    } else {
      return "";
    }
  }

  function getIconForEventStatus(status) {
    if(status === fileIngestedName) {
      return (
        <div data-tip="Event in BigQuery">
          <img src={bigqueryIcon} className="requestIcon" />
        </div>
      );
    } else if(status === fileProcessingStartedName) {
      return (
        <div data-tip="Event in Dataflow">
          <img src={dataflowIcon} className="requestIcon" />
        </div>
      );
    } else if(status === fileSuccessName) {
      return (
        <div data-tip="File Uploaded Awaiting Processing">
          <img src={cloudGenericIcon} className="requestIcon" />
        </div>
      );
    } else if(status === fileStartName) {
      return (
        <div data-tip="Event on Robot">
          <img src={robotArmIcon} className="requestIcon" />
        </div>
      );
    } else if(status === fileFailureName) {
      return (
        <div data-tip="Event failed">
          <img src={errorIcon} className="requestIcon"/>
        </div>
      );
    }
  }

  function setQueryRequestView(saveEventRequestId) {
    setRequestIdJsonDisplay(saveEventRequestId);
    const newDisplayData = {};
    Object.assign(newDisplayData, saveEventIdToDocument[saveEventRequestId]);
    setDisplayData(newDisplayData);
  }

  function setHlcView(hlcName) {
    // Unset display data if we change HLCs
    if(selectedHlcName !== hlcName) {
      setDisplayData("");
    }
    setSelectedHlcName(hlcName);
    setHlcViewEnabled(true);
    setRequestIdSearch("");
  }

  const renderSuggestion = suggestion => (
    <div>
      {suggestion}
    </div>
  );

  function getSuggestions(value, suggestionList)  {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : suggestionList.filter(s =>
      s.toLowerCase().slice(0, inputLength) === inputValue
    ).slice(0, NUM_SUGGESTIONS_TO_DISPLAY);
  };

  function clearHlcSuggestions() {
    setHlcNameSuggestions([]);
  }

  function clearTypeSuggestions() {
    setTypeSuggestions([]);
  }

  function onSuggestionSelectedHlcName(event, { suggestionValue }) {
    setHlcNameSearch(suggestionValue);
    setSaveEventTypeSearch(saveEventTypeInput);
    setHlcViewEnabled(false);
  }

  function onSuggestionSelectedType(event, { suggestionValue }) {
    setSaveEventTypeSearch(suggestionValue);
    setHlcNameSearch(hlcNameInput);
    setHlcViewEnabled(false);
  }

  function getMessageForEventStatus(status) {
    if(status === fileIngestedName) {
      return "Added to BigQuery ";
    } else if(status === fileProcessingStartedName) {
      return "File processing started ";
    } else if(status === fileSuccessName || status === fileResumeName) {
      return "File uploaded ";
    } else if(status === fileStartName) {
      return "Upload started ";
    } else if(status === fileFailureName) {
      return "Upload failed ";
    }
  }

  function getSaveEventResultList() {
    if (saveEventRequestsLoaded) {
      return (
        <div className="saveEventResultList">
          {
            Object.values(saveEventIdToDocument).map((saveEventDoc, index) => (
              <div className="queryResultListItem">
                <ReactTooltip />
                <li data-tip={saveEventDoc.id} className={"querySaveEventEntry " + " " + getClassForRequestFilter(saveEventDoc.id)}
                    onClick={ () => setQueryRequestView(saveEventDoc.id) }
                    key={saveEventDoc.id}> {saveEventDoc.id.substring(0, 8)} </li>
                <div className="saveEventStatus">
                  <ReactTooltip />
                  {getIconForEventStatus(saveEventDoc["status"])}
                  <p className="requestItemUploadedOn" >
                    { getMessageForEventStatus(saveEventDoc["status"]) }
                    <ReactTimeAgo date={saveEventDoc.upload_started_on} locale="en-US"/>
                  </p>
                </div>
              </div>
            ))
          }
        </div>
      );
    } else {
      return (
        <div className="saveEventLoadingList" >
          <LoadingIcons.ThreeDots className="loadingIcon" fill="#0095FF"/>
        </div>
      );
    }
  }

  function getHlcList() {
    if (hlcViewEnabled) {
      return (
          <div className= "hlcView">
            {
              Object.entries(hlcNameToStatus).map(([hlcName, hlcStatus], index) => (
                <div className="hlcViewRow">
                  <li className={"hlcEntry " + getClassForHlcFilter(hlcName)}  onClick={ () => setHlcView(hlcName) } key={ hlcName }> {hlcName} </li>
                  <ReactTooltip />
                  {getHlcStatusIndicator(hlcStatus)}
                </div>
              ))
            }
          </div>
      );
    }
  }


  // TODO: add button to return to hlc view
  return (
    <div className="App">
      <header className="App-header">
        <div className="statusIndicator">
          <p>Last FileEvent received <ReactTimeAgo date={mostRecentSaveEventTime} locale="en-US"/></p>
          { getFirestoreStatusIndicator(mostRecentSaveEventTime) }
        </div>
        <div className="inputBoxes">
          <div className="requestInputBox">
            <input placeholder="SaveEvent Request UUID" name="request_id" id="requestIdInput" onChange={onChangeRequestId} onKeyPress={onEnterRequestId} value={requestIdInput} size="50" />
            <input value="Submit" type="submit" onClick={onSubmitRequestId}/>
          </div>
          <div className="queryInputBoxes">
            <div className="suggestHlcName">
              <Autosuggest suggestions={hlcNameSuggestions}
                           onSuggestionSelected={onSuggestionSelectedHlcName}
                           onSuggestionsFetchRequested={(value) => setHlcNameSuggestions(getSuggestions(value['value'], hlcList))}
                           onSuggestionsClearRequested={clearHlcSuggestions}
                           getSuggestionValue={(s) => s}
                           renderSuggestion={renderSuggestion}
                           inputProps={hlcAutosuggestProps}/>
            </div>
            <div className="suggestType">
              <Autosuggest suggestions={typeSuggestions}
                           onSuggestionSelected={onSuggestionSelectedType}
                           onSuggestionsFetchRequested={(value) => setTypeSuggestions(getSuggestions(value['value'], typeList))}
                           onSuggestionsClearRequested={clearTypeSuggestions}
                           getSuggestionValue={(s) => s}
                           renderSuggestion={renderSuggestion}
                           inputProps={typeAutosuggestProps}/>
            </div>
            <input value="Submit" type="submit" onClick={onSubmitQuery}/>
          </div>
        </div>

      </header>
      <body>
        <div className="dataView">
          {getHlcList()}
          {getSaveEventResultList()}
        </div>
        <div className="saveEventJsonData">
          <pre>  {displayData ? JSON.stringify(displayData, null, 2) : null} </pre>
        </div>
      </body>
    </div>
  );
}

export default App;
